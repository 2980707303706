<template>
  <div>
    <div class="scroolHorizontal">
      <!-- List Header -->
      <div class="flex">
        <div class="w-full flex">
          <div class="tabHeader orderNoWidth w-1/12 w-full">
            {{ $t("No_Number") }}
          </div>
          <div class="tabHeader companyWidth w-1/12 w-full">
            {{ $t("Company") }}
          </div>
          <div class="tabHeader ownerWidth w-1/12 w-full">
            {{ $t("Owner") }}
          </div>
          <div class="tabHeader patientWidth w-1/12 w-full">
            {{ $t("Patient") }}
          </div>
          <div class="tabHeader creditWidth w-1/12 w-full">
            {{ $t("Credit") }}
          </div>
          <div class="tabHeader statusWidth w-1/12 w-full">
            {{ $t("Status") }}
          </div>
          <div class="tabHeader dateWidth w-1/12 w-full">
            {{ $t("Date") }}
          </div>
        </div>
      </div>
      <!-- List -->
      <orderList
        class="orderBar"
        v-for="order in orders"
        :order="order"
        :key="order.id"
      />
    </div>
    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="handleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import orderList from "./components/OrderList";

import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      orders: [],
    };
  },
  components: {
    orderList,
  },
  methods: {
    handleChangePage() {
      this.getOrders();
    },
    getOrders() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(
        API.BASEURL + API.DENTAL_PROVIDER_ORDERS,
        this.handleGetOrdersRequest
      );
    },
    handleGetOrdersRequest(status, data) {
      this.$vs.loading.close();
      if (status >= 200 && status < 300) {
        this.orders = data.results;
      }
    },
  },
};
</script>

<style>
.orderNoWidth {
  max-width: 60px;
  min-width: 50px;
}
.companyWidth {
  max-width: 250px;
  min-width: 200px;
}
.ownerWidth {
  max-width: 300px;
  min-width: 250px;
}
.patientWidth {
  max-width: 250px;
  min-width: 200px;
}
.creditWidth {
  max-width: 80px;
  min-width: 60px;
}
.statusWidth {
  max-width: 120px;
  min-width: 100px;
}
.dateWidth {
  max-width: 200px;
  min-width: 170px;
}
.downloadWidth {
  max-width: 200px;
  min-width: 170px;
}
</style>

<style lang="scss" scoped>
.orderBar {
  cursor: pointer;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 3px 10px 0px #ccc3;
    transition: all 0.5s;
  }
}
.scroolHorizontal {
  width: 100%;
  overflow: auto;
}
.tabHeader {
  padding: 10px 15px;
  border: 0;
  text-align: left;
  font-size: 0.85rem;
  font-weight: 800;
  background-color: white;
}
.boxShadow {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>