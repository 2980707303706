<template>
  <div class="flex w-full">
    <div
      class="valueBackground orderNoWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ order.id }}</p>
    </div>
    <div
      class="valueBackground companyWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ order.company }}</p>
    </div>
    <div
      class="valueBackground ownerWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ order.owner }}</p>
    </div>
    <div
      class="valueBackground patientWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ order.patient }}</p>
    </div>
    <div
      class="valueBackground creditWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ order.credit }}</p>
    </div>
    <div
      class="valueBackground statusWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <vs-chip :color="getOrderStatusColor(order.order_status)">{{
        getOrderStatus(order.order_status)
      }}</vs-chip>
    </div>

    <div
      class="valueBackground dateWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p class="orderDateP">
        {{ getRelativeTime(order.created) }}
      </p>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      downloadCancelButtonTitle: this.$t("Download"),
      downloadCancelButtonColor: "primary",
      isDownloading: false,
      filePersent: 0,
      request: axios.CancelToken.source(),
    };
  },
  props: ["order"],
  methods: {
    openOrderDetail() {
      var queryData = {
        id: this.order.id,
      };

      this.$router
        .push({
          name: "provider_order_detail",
          query: queryData,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - HH:mm");
    },
    getOrderStatus(status) {
      if (status == "S") {
        return this.$t("New");
      } else if (status == "P") {
        return this.$t("Processing");
      } else if (status == "R") {
        return this.$t("Ready");
      } else if (status == "W") {
        return this.$t("Waiting");
      } else if (status == "C") {
        return this.$t("Cancelled");
      } else {
        return this.$t("New");
      }
    },
    getOrderStatusColor(status) {
      if (status == "W") return "#389CA3";
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "S") return "#31bdf5";
      if (status == "C") return "danger";
      return "primary";
    },
  },
};
</script>

<style lang="scss" scoped>
.valueBackground {
  background-color: white;
  padding: 10px 15px;
  .p {
    font-size: 1rem;
  }
}
</style>